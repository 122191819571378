import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListProps,
  styled,
} from '@mui/material';
import React from 'react';

export type NavigationItem = {
  url: string;
  label: string;
  isButton?: boolean;
};

interface NavigationMenuProps extends ListProps {
  items: NavigationItem[];
}

const NavigationList = styled(List)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  listStyleType: 'none',
  margin: 0,
  padding: 0,
  gap: theme.spacing(8),
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(3),
  },
}));

const NavigationListItem = styled(ListItem)(({ theme }) => ({
  textDecoration: 'none',
  width: 'auto',
}));

const NavigationButton = styled(Button)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 'bold',
  color: theme.palette.secondary.dark,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  borderRadius: 25,
  '&:hover': {
    color: theme.palette.text.secondary,
  },
}));

export const NavigationMenu: React.FC<NavigationMenuProps> = ({
  items,
  ...rest
}) => {
  return (
    <NavigationList {...rest}>
      {items.map(({ url, label, isButton }, index) => (
        <NavigationListItem key={index} disableGutters disablePadding>
          {isButton ? (
            <NavigationButton
              href={url}
              variant='contained'
              color='secondary'
              disableElevation
            >
              {label}
            </NavigationButton>
          ) : (
            <ListItemButton component='a' href={url}>
              <ListItemText>{label}</ListItemText>
            </ListItemButton>
          )}
        </NavigationListItem>
      ))}
    </NavigationList>
  );
};
