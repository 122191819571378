import { Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import {
  AboutSection,
  BookingCta,
  Carousel,
  Footer,
  MainBanner,
  Navigation,
  PageSection,
  SubBanner,
  Testimonials,
  WorkProcess,
} from '../../components';
import usePerson from '../../hooks/usePerson';
import UnderConstruction from '../under-construction/index.page';

const underConstruction = process.env.REACT_APP_UNDER_CONSTRUCTION === '1';
const personId = process.env.REACT_APP_CONTENTFUL_PERSON_ID

const Home: React.FC = () => {
  // @TODO: Page title
  const theme = useTheme();
  const { get, data: person, loading } = usePerson();

  useEffect(() => {
    if (!!personId) {
      get(personId)
    }
  }, [get])

  return underConstruction ? (
    <UnderConstruction />
  ) : loading ? (
    // TODO: Loading state
    <></>
  ) : !person ? (
    // TODO: No person data retrieved
    <></>
  ) : (
    <>
      <Navigation />
      <PageSection padding={theme.spacing(12)}>
        <MainBanner />
      </PageSection>

      <PageSection
        padding={theme.spacing(12)}
        backgroundColor={theme.extended.background.dark}
      >
        <SubBanner />
      </PageSection>

      <PageSection padding={theme.spacing(12)}>
        <AboutSection skills={person?.skills}/>
      </PageSection>

      <PageSection
        padding={theme.spacing(12)}
        backgroundColor={theme.palette.background.paper}
      >
        <Typography variant='h1' textAlign='center'>
          Satisfied Clients
        </Typography>
        <Carousel
          data={person.projects.map(({ title, description, url, featuredImage }) => ({
            image: featuredImage.url,
            title,
            subtitle: description,
            url,
          }))}
        />
      </PageSection>

      <PageSection id='work-process' padding={theme.spacing(12)}>
        <WorkProcess />
      </PageSection>

      <PageSection
        padding={theme.spacing(12)}
        backgroundColor={theme.palette.background.paper}
      >
        <Testimonials
          data={person.testimonials.map(({ client, ...testimonial }) => ({
            ...testimonial,
            name: client.name,
            title: client.title,
            profilePicture: client.profilePicture.url
          }))}
        />
      </PageSection>

      <PageSection padding={theme.spacing(12)}>
        <BookingCta />
      </PageSection>

      <Footer links={{
        linkedIn: person.linkedIn,
        facebook: person.facebook,
        instagram: person.instagram,
        twitter: person.twitter,
      }}/>
    </>
  );
};

export default Home;
