import { ContentfulClientApi } from 'contentful';
import client from '../adapters/contentful/client';
import BaseContentType from '../content_types/base';
import BaseType from '../types/base';

abstract class BaseWebservice<CT extends BaseContentType, T extends BaseType> {
  abstract contentType: string;
  abstract parse(data: CT): T;

  client: ContentfulClientApi;

  constructor() {
    this.client = client
  }

  async getById(id: string, query: any = {}) {
    try {
      const { fields } = await this.client.getEntry<CT>(id, {
        content_type: this.contentType,
        ...query,
      });

      return this.parse(fields);
    } catch (error) {
      // TODO: Handle errors
    }
  }
}

export default BaseWebservice
