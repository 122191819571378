import React, { useEffect, useState } from 'react';
import PageTitleContext from '../contexts/PageTitleContext';
import Home from './home/index.page';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../styles/theme';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';

const App: React.FC = () => {
  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => {
    document.title = `${pageTitle} | Whitney Agunday`;
  }, [pageTitle]);

  return (
    <PageTitleContext.Provider value={{ pageTitle, setPageTitle }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route
              path='*'
              // TODO: Add routes for sub pages
              element={<Navigate to='/' />}
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </PageTitleContext.Provider>
  );
};

export default App;
