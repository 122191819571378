import { Components, createTheme } from '@mui/material/styles';

// add custom theme variables via module augmentation
// see: https://mui.com/customization/theming/#custom-variables
declare module '@mui/material/styles' {
  interface Theme {
    extended: {
      border: {
        color: string;
      };
      background: {
        light: string;
        dark: string;
        darker: string;
      };
    };
  }
  interface Palette {
    accent: {
      main: string;
    };
    dark: {
      main: string;
    };
  }

  interface ThemeOptions {
    extended: {
      border: {
        color: string;
      };
    };
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
  }
}

const helperTheme = createTheme();

const palette = {
  primary: {
    main: '#263842',
  },
  secondary: {
    main: '#D8E9F3',
    dark: '#263842',
  },
  accent: {
    main: '#FF6B00',
    light: '#FFA666',
  },
  dark: {
    main: '#5B4A42',
  },
  background: {
    default: '#F6F6F6',
    paper: '#FAFAFA',
  },
  text: {
    primary: '#263842',
    secondary: '#FFFFFF',
  },
};

const components: Components = {
  MuiCssBaseline: {
    styleOverrides: {
      'html, body, #root': {
        background: palette.background.default,
        fontFamily: 'Hind, sans-serif',
        minHeight: '100vh',
        height: '100%',
        width: '100%',
        padding: 0,
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      },
      a: {
        textDecoration: 'none',
        cursor: 'pointer',
        color: 'inherit',
        ':hover': {
          textDecoration: 'underline'
        }
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      h1: {
        fontFamily: 'Hind, sans-serif',
        color: palette.text.primary,
        fontSize: 64,
        fontWeight: 600,
        letterSpacing: '0.01rem',
        [helperTheme.breakpoints.down('md')]: {
          fontSize: 36,
        },
      },
      h4: {
        fontSize: 22,
        fontWeight: 'bold',
      },
      h5: {
        fontSize: 16,
        fontWeight: 'bold',
      },
      body1: {
        color: palette.text.primary,
        fontFamily: 'Hind, sans-serif',
      },
      body2: {
        fontSize: 18,
        fontFamily: 'Hind, sans-serif',
      },
      subtitle1: {
        fontFamily: 'Hind, sans-serif',
        fontSize: 22,
        lineHeight: '140%',
        letterSpacing: '0.02rem',
        [helperTheme.breakpoints.down('md')]: {
          fontSize: 18,
        },
      },
      subtitle2: {
        fontFamily: 'Nanum Brush Script, cursive',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
    variants: [
      {
        props: { variant: 'primary' },
        style: {
          backgroundColor: palette.accent.main,
          color: palette.text.secondary,
          borderRadius: 20,
          fontSize: 16,
          lineHeight: '140%',
          letterSpacing: '0.02rem',
          fontWeight: 'bold',
          paddingLeft: 20,
          paddingRight: 20,
          '&:hover': {
            backgroundColor: palette.accent.light,
          },
          [helperTheme.breakpoints.down('md')]: {
            fontSize: 14,
          },
        },
      },
      {
        props: { variant: 'secondary' },
        style: {
          backgroundColor: palette.secondary.main,
          color: palette.text.primary,
          borderRadius: 25,
          fontSize: 16,
          lineHeight: '140%',
          letterSpacing: '0.02rem',
          fontWeight: 'bold',
          paddingLeft: 30,
          paddingRight: 30,
          '&:hover': {
            backgroundColor: palette.text.primary,
            color: palette.secondary.main,
          },
          [helperTheme.breakpoints.down('md')]: {
            fontSize: 14,
          },
        },
      },
    ],
  },
};

const typography = {
  fontFamily: 'Hind, sans-serif',
};

const extended = {
  border: {
    color: '#F0F0F0',
  },
  background: {
    light: '#F9F9F9',
    dark: '#E9EBF3',
    darker: '#D7D7D7',
  },
};

const theme = createTheme({
  palette,
  components,
  typography,
  extended,
});

export default theme;
