import { Box, Button, ButtonProps, styled } from '@mui/material';
import React from 'react';

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  columnGap: theme.spacing(1),
}));

interface ButtonWithIconProps extends Omit<ButtonProps, 'children'> {
  label: string;
  icon?: string;
}

export const ButtonWithIcon: React.FC<ButtonWithIconProps> = ({
  label,
  icon,
  style,
  ...rest
}) => {
  return (
    <Button {...rest}>
      <ContentWrapper>
        <Box display='flex'>{icon && <img src={icon} alt={label} height={40} />}</Box>
        <Box>{label}</Box>
      </ContentWrapper>
    </Button>
  );
};
