import { useCallback, useState } from 'react';
import PersonWebservice from '../webservices/person'
import Person from '../types/person'

const usePerson = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Person | undefined>();

  const get = useCallback(async (id: string) => {
    const webservice = new PersonWebservice();
    setLoading(true)
    try {
      const result = await webservice.getById(id, { include: 3 })
      setData(result)
    } catch (error) {
      console.log(`Error retrieving person of id ${id}`)
    }
    setLoading(false)
  }, [])

  return { get, loading, data }
}

export default usePerson;
