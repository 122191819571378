import { useMediaQuery } from '@material-ui/core';
import {
  Box,
  Card,
  CardContent,
  Pagination,
  PaginationItem,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { StarRating } from '.';

export const TestimonialCard = styled(Card)(({ theme }) => ({
  borderRadius: 24,
  margin: 30,
  overflow: 'visible',
  backgroundColor: theme.extended.background.light,
  padding: theme.spacing(3),
  boxShadow: '0px 4px 30px rgba(30, 45, 53, 0.05)',
  '&.current': {
    boxShadow: '0px 4px 30px rgba(30, 45, 53, 0.25)',
  },
  // custom xxs breakpoint
  '@media (min-width:0px) and (max-width:399.95px)': {
    marginLeft: 20,
    marginRight: 20,
  },
}));

const TestimonialInfoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(3),
  columnGap: theme.spacing(3),
}));

const ClientInfoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  columnGap: theme.spacing(2),
  alignItems: 'center',
  flexGrow: 1,
  // custom xxs breakpoint
  '@media (min-width:0px) and (max-width:399.95px)': {
    flexWrap: 'wrap',
    rowGap: theme.spacing(1),
  },
}));

const ClientImageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

const ClientDetailsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'space-between',
  columnGap: theme.spacing(3),
  flexWrap: 'wrap',
}));

const ClientName = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  fontWeight: 'bold',
}));

const RatingWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  columnGap: theme.spacing(2),
  alignItems: 'center',
}));

const RatingValue = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  height: 24, // intentional for horizontal alignment
  fontWeight: 'bold',
}));

interface TestimonialsProps {
  data: {
    content: string;
    name: string;
    title: string;
    profilePicture: string;
    rating: number,
  }[]
}

export const Testimonials: React.FC<TestimonialsProps> = ({ data }) => {
  const theme = useTheme();
  const mediumScreens = useMediaQuery(theme.breakpoints.down('md'));
  const smallScreens = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleChangeIndex = (index: number) => setCurrentSlide(index);

  return (
    <>
      <Typography variant='h1' textAlign='center'>
        Don't take my word for it
      </Typography>
      <Box>
        {/*
          TODO: Fix componentWillReceiveProps issue
          See: https://github.com/oliviertassinari/react-swipeable-views/issues/534
        */}
        <SwipeableViews
          enableMouseEvents
          style={{
            paddingRight: !mediumScreens ? 120 : !smallScreens ? 80 : 0,
          }}
          index={currentSlide}
          onChangeIndex={handleChangeIndex}
        >
          {data.map(({ content, name, title, profilePicture, rating }, index) => (
            <TestimonialCard
              key={index}
              className={index === currentSlide ? 'current' : ''}
            >
              <CardContent>
                <Typography style={{ fontSize: 20 }}>{content}</Typography>
                <TestimonialInfoWrapper>
                  <ClientInfoWrapper>
                    <ClientImageWrapper>
                      <img
                        src={profilePicture}
                        alt=''
                        height={50}
                        style={{ borderRadius: 26 }}
                      />
                    </ClientImageWrapper>
                    <ClientDetailsWrapper>
                      <Box>
                        <ClientName>{name}</ClientName>
                        <Typography>{title}</Typography>
                      </Box>
                      <RatingWrapper>
                        <RatingValue>{rating}</RatingValue>
                        <StarRating rating={rating} />
                      </RatingWrapper>
                    </ClientDetailsWrapper>
                  </ClientInfoWrapper>
                </TestimonialInfoWrapper>
              </CardContent>
            </TestimonialCard>
          ))}
        </SwipeableViews>
      </Box>
      <Pagination
        count={data.length}
        page={currentSlide + 1}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            page=''
            style={{
              padding: 0,
              height: 10,
              minHeight: 10,
              width: item.selected ? 30 : 10,
              minWidth: item.selected ? 30 : 10,
              backgroundColor: item.selected
                ? theme.palette.primary.main
                : theme.extended.background.darker,
            }}
          />
        )}
        onChange={(_, page) => {
          handleChangeIndex(page - 1);
        }}
        hideNextButton
        hidePrevButton
        style={{
          justifyContent: 'center',
          display: 'flex',
        }}
      />
    </>
  );
};
