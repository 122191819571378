import { Box, styled, Typography } from '@mui/material';
import React from 'react';
import { ButtonWithIcon } from '.';

const MainContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  columnGap: theme.spacing(5),
  backgroundColor: theme.palette.secondary.light,
  borderRadius: 24,
  overflow: 'hidden',
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const CtaImageContainer = styled(Box)(({ theme }) => ({
  width: '22%',
  height: 0, // intentional. to use whatever the other flex element height is
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const BookingCta: React.FC = () => (
  <>
    <MainContainer>
      <ContentWrapper>
        <Box>
          <Typography variant='h1'>Want more leads and bookings?</Typography>
        </Box>
        <Typography variant='subtitle1'>
        Have a project that needs my expertise? A website revamp or an awesome startup idea? Let's talk and I'm excited to help!
        </Typography>
        <Box>
          <ButtonWithIcon
            variant='primary'
            label='Book a Call Now'
            icon='/images/chat-dots.png'
            href='https://calendly.com/thewebsynthesis/discoverycall'
          />
        </Box>
      </ContentWrapper>
      <CtaImageContainer>
        <img
          src='/images/phone-calendly.png'
          alt=''
          height={600}
          style={{
            transform: 'rotate(-20deg)',
          }}
        />
      </CtaImageContainer>
    </MainContainer>
  </>
);
