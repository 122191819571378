import { Box, styled, Typography, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';

const Subtitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    marginTop: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    padding: 0,
  },
}));

interface CallToActionProps {
  title: string;
  subtitle: string;
  button: ReactNode;
  moreInfo?: ReactNode;
}

export const CallToAction: React.FC<CallToActionProps> = ({
  title,
  subtitle,
  button,
  moreInfo,
}) => {
  const theme = useTheme();
  return (
    <Box>
      <Typography variant='h1'>{title}</Typography>
      <Subtitle variant='subtitle1'>{subtitle}</Subtitle>
      {moreInfo}
      <Box mt={theme.spacing(4)}>{button}</Box>
    </Box>
  );
};
