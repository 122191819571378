import { Box, styled, useTheme } from '@mui/material';
import React from 'react';
import { Circle } from '.';

const AccentCircle = styled(Circle)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: 18,
    height: 18,
    borderRadius: 18,
  },
}));

export const CirclesAccent: React.FC = () => {
  const theme = useTheme();

  return (
    <Box display='flex' alignItems='center' columnGap={theme.spacing(1)}>
      <AccentCircle style={{ background: theme.palette.accent.main }} />
      <AccentCircle style={{ background: theme.palette.secondary.light }} />
      <AccentCircle style={{ background: theme.palette.dark.main }} />
    </Box>
  );
};
