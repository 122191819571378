import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Drawer, styled, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { NavigationMenu } from '.';
import { NavigationItem } from './NavigationMenu';

const Menu = styled(Drawer)(({ theme }) => ({
  left: 'unset',
  right: 'unset',
  bottom: 'unset',
  top: 82,
  zIndex: 200,
  width: '100%',
  '.MuiPaper-root': {
    position: 'relative',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.extended.border.color}`,
  },
}));

interface BurgerMenuProps {
  items: NavigationItem[];
}

export const BurgerMenu: React.FC<BurgerMenuProps> = ({ items }) => {
  const theme = useTheme();
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <Button
        color='secondary'
        variant='contained'
        disableElevation
        onClick={() => setShowMenu(!showMenu)}
      >
        {showMenu ? <CloseIcon /> : <MenuIcon />}
      </Button>
      <Menu
        anchor='top'
        open={showMenu}
        onClose={() => setShowMenu(false)}
        hideBackdrop
        elevation={0}
      >
        <NavigationMenu
          items={items}
          style={{
            flexDirection: 'column',
            alignItems: 'start',
            gap: theme.spacing(1),
          }}
        />
      </Menu>
    </>
  );
};
