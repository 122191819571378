import { Box, styled } from '@mui/material';
import React, { memo } from 'react';
import { StackedCarouselSlideProps } from 'react-stacked-center-carousel';
import { icons } from '../utils/icons';

const SlideWrapper = styled(Box)(({ theme }) => ({
  filter: 'drop-shadow(-2px -1px 7px rgba(0, 0, 0, 0.25))',
  backgroundColor: theme.palette.background.paper,
  borderRadius: 20,
  '&:hover': {
    cursor: 'pointer',
  },
  '& > img': {
    opacity: 0.5,
    transition: 'all 0.4s ease',
    filter: 'brightness(100%)',
  },
  '&.active': {
    '& > img': {
      opacity: 1,
    },
    '&:hover': {
      '& > img': {
        filter: 'brightness(40%)',
      },
      '&:after': {
        content: "' '",
        transition: 'all 0.4s ease',
        backgroundImage: `url(${icons.actions.link})`,
        position: 'absolute',
        height: 56,
        width: 56,
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
      },
    },
  },
}));

export const CarouselSlide = memo(
  ({
    data,
    dataIndex,
    isCenterSlide,
    swipeTo,
    slideIndex,
  }: StackedCarouselSlideProps) => {
    const { image, title, url } = data[dataIndex];

    return (
      <SlideWrapper
        className={isCenterSlide ? 'active' : undefined}
        onClick={() => {
          if (!isCenterSlide) {
            swipeTo(slideIndex);
          } else {
            window.open(url, '_blank');
          }
        }}
      >
        <img
          src={image}
          alt={title}
          style={{
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            borderRadius: 10,
          }}
          draggable={false}
        />
      </SlideWrapper>
    );
  }
);
