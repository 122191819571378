import { Box, Container, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useContext } from 'react';
import PageTitleContext from '../../contexts/PageTitleContext';
import { CirclesAccent, LogoWithTitle, SocialLinks } from '../../components';

const Header = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.primary.main,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const Footer = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.text.secondary,
  textAlign: 'center',
  fontSize: 12,
}));

const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

const LargeHeading = styled(Typography)(({ theme }) => ({
  fontSize: 64,
  fontWeight: 600,
  [theme.breakpoints.down('sm')]: {
    fontSize: 48,
  },
}));

const UnderConstruction: React.FC = () => {
  const theme = useTheme();
  const { setPageTitle } = useContext(PageTitleContext);
  setPageTitle('Under Construction');

  return (
    <>
      <Header>
        <Container maxWidth='xl'>
          <Box display='flex' justifyContent='space-between'>
            <LogoWithTitle />
            <CirclesAccent />
          </Box>
        </Container>
      </Header>
      <Container
        maxWidth='xl'
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          height: '100%',
          padding: theme.spacing(4),
        }}
      >
        <Content>
          <LargeHeading variant='h1'>Howdy! 👋</LargeHeading>
          <Typography variant='body1'>
            I am working hard to get this up and running.
          </Typography>
          <Box py={theme.spacing(3)}>
            <Typography
              variant='subtitle2'
              style={{
                fontSize: 20,
                lineHeight: 2,
                transform: 'rotate(-12deg)',
              }}
            >
              Follow me!
            </Typography>
            <SocialLinks
              links={{
                facebook: 'thewebsynthesis',
                twitter: 'thewebsynthesis',
                instagram: 'thewebsynthesis',
                linkedIn: 'whitneyagunday',
              }}
            />
          </Box>
        </Content>
      </Container>
      <Footer>© Copyright 2021 Whitney Agunday</Footer>
    </>
  );
};

export default UnderConstruction;
