import { Box, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { ButtonWithIcon, CallToAction, TwoColumnBox } from '.';
import { ResponsiveImage } from './ResponsiveImage';

export const SubBanner: React.FC = () => {
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <TwoColumnBox gridTemplateColumns='1fr 2fr'>
      <Box order={mediumScreen ? 2 : 1}>
        <ResponsiveImage src='/images/banner-build.png' alt='' />
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        order={mediumScreen ? 1 : 2}
      >
        <Box>
          <CallToAction
            title='Get My Free "Branding Guidelines"'
            subtitle='Get an idea of how you can optimize your website designs through my strategic design guidelines. This free guide will provide you with practical tips and actionable insights on how to create strong brand presence online whether you’re just starting out or looking to revamp your existing plumbing website.'
            button={
              <ButtonWithIcon
                variant='secondary'
                label={'Get your free guide now!'}
                icon='/images/check-blue.png'
                href='#'
                style={{
                  marginTop: theme.spacing(4),

                }}
              />
            }
          />
        </Box>
      </Box>
    </TwoColumnBox>
  );
};
