import {
  Box,
  Card,
  CardContent,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';

const CardList = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  display: 'grid',
  gridGap: theme.spacing(3),
  gridTemplateColumns: 'repeat(3, 1fr)',
  [theme.breakpoints.down('md')]: {
    marginRight: 'auto',
    marginLeft: 'auto',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: 20,
  boxShadow: 'none',
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(4),
}));

const CardImageContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.extended.background.dark,
  height: 160,
  textAlign: 'center',
}));

export const WorkProcess: React.FC = () => {
  const theme = useTheme();

  /**
   * @todo move to data
   */
  const steps = [
    {
      image: '/images/calendar.png',
      title: 'Book a strategy call',
      body: "Let's discuss your paint points, understand the problem and craft a tailored-fit solution.",
    },
    {
      image: 'images/documents.png',
      title: 'Website Analysis',
      body: "Our team will conduct a thorough analysis of your website to understand how it's currently performing and identify areas for improvement.",
    },
    {
      image: '/images/check-blue.png',
      title: 'Design Solution',
      body: "Based on the analysis, we'll develop a design solution that addresses your specific needs and drives results.",
    },
    {
      image: '/images/bar-graph.png',
      title: 'Kick-off development',
      body: 'Sit back as I start building your website while I give you updates as we go. ',
    },
    {
      image: '/images/megaphone-exclaim.png',
      title: 'Deploy to staging',
      body: 'Get a preview of what your website will look before we publish it to the world.',
    },
    {
      image: '/images/confetti.png',
      title: 'Launch!',
      body: "When everything is all set and ready, let's launch your website and celebrate!",
    },
  ];

  /**
   * @todo carousel on small screen?
   */
  return (
    <>
      <Box textAlign='center'>
        <Typography variant='h1'>Welcome to our plumbing business website optimization service</Typography>
        <Typography variant='h5'>
          Our 6-step process is designed to help increase your sales and website traffic through improved user experience design. Here's what you can expect when you work with us:
        </Typography>
      </Box>
      <CardList>
        {steps.map(({ image, title, body }, index) => (
          <StyledCard key={index}>
            <CardImageContainer>
              <img src={image} alt={title} height={160} />
            </CardImageContainer>
            <StyledCardContent>
              <Typography variant='h4' marginBottom={theme.spacing(3)}>
                {title}
              </Typography>
              <Typography>{body}</Typography>
            </StyledCardContent>
          </StyledCard>
        ))}
      </CardList>
    </>
  );
};
