import { Box, styled } from '@mui/material';
import React from 'react';

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: 'auto',
    ' img': {
      width: '100%'
    }
  },
}))

export const ResponsiveImage = ({alt, ...props}: React.ImgHTMLAttributes<HTMLImageElement>) => (
  <StyledBox>
    <img alt={alt ?? ''} {...props} />
  </StyledBox>
)