export const icons = {
  logo: {
    dark: '/icons/logo-dark.svg',
    light: '/icons/logo-light.svg',
  },
  avatars: {
    primary: '/icons/avatar-1.svg',
    secondary: '/icons/avatar-2.svg',
    alternate: '/icons/avatar-3.svg',
  },
  socials: {
    linkedin: '/icons/linkedin-dark.svg',
    instagram: '/icons/instagram-dark.svg',
    facebook: '/icons/facebook-dark.svg',
    twitter: '/icons/twitter-dark.svg',
  },
  tools: {
    asana: '/icons/asana.svg',
    elementor: '/icons/elementor.svg',
    figma: '/icons/figma.svg',
    photoshop: 'icons/photoshop.svg',
    wordpress: 'icons/wordpress.svg',
  },
  rating: {
    star: '/icons/star.svg',
    halfStar: '/icons/half-star.svg',
  },
  actions: {
    link: '/icons/external-link.svg',
  },
};
