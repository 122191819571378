import { Box, styled, Typography, useTheme } from '@mui/material';
import React from 'react';
import { icons } from '../utils/icons';

const LogoTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 17,
  lineHeight: 1,
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const LogoWithTitle: React.FC = () => {
  const theme = useTheme();

  return (
    <Box display='flex' columnGap={theme.spacing(2)}>
      <img src={icons.logo.light} alt='' height={50} />
      <Box display='flex' alignItems='center' maxWidth={130}>
        <LogoTitle variant='body2'>Brand Yourself & Build a Website.</LogoTitle>
      </Box>
    </Box>
  );
};
