import { Box, Container, styled, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { BurgerMenu, NavigationMenu } from '.';
import { icons } from '../utils/icons';
import { NavigationItem } from './NavigationMenu';

const NavigationContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const navigationItems: NavigationItem[] = [
  { url: '/', label: 'Home' },
  { url: '/about', label: 'About' },
  { url: '/services', label: 'Services' },
  { url: 'https://calendly.com/thewebsynthesis/discoverycall', label: 'Book a Call Now', isButton: true },
];

export const Navigation: React.FC = () => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      style={{
        borderBottom: `1px solid ${theme.extended.border.color}`,
        backgroundColor: theme.palette.background.default,
        zIndex: 999,
      }}
    >
      <NavigationContainer maxWidth='xl'>
        <Box>
          <img src={icons.logo.dark} height={mediumScreen ? 40 : 70} alt='' />
        </Box>
        <Box>
          {smallScreen ? (
            <BurgerMenu items={navigationItems} />
          ) : (
            <NavigationMenu items={navigationItems} />
          )}
        </Box>
      </NavigationContainer>
    </Box>
  );
};
