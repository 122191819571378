import { Box } from '@mui/material';
import React from 'react';
import { ButtonWithIcon, CallToAction, TwoColumnBox } from '.';
import { ResponsiveImage } from './ResponsiveImage';

export const MainBanner: React.FC = () => {
  return (
    <TwoColumnBox gridTemplateColumns='2fr 1fr'>
      <Box display='flex' flexDirection='column' justifyContent='center'>
        <Box>
          <CallToAction
            title='Brand Yourself, Build Your Website'
            subtitle='Elevating website traffic and sales for plumbing businesses through exceptional user experience design.'
            button={
              <ButtonWithIcon
                variant='primary'
                label="let's get started"
                icon='/images/megaphone-exclaim.png'
                href='#work-process'
              />
            }
          />
        </Box>
      </Box>
      <Box>
        <ResponsiveImage src='/images/banner-brand.png' alt='' />
      </Box>
    </TwoColumnBox>
  );
};
