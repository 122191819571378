import { Box, styled } from '@mui/material';

export const TwoColumnBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridGap: theme.spacing(6),
  '& > *:first-of-type': {
    justifySelf: 'start',
  },
  '& > *:last-child': {
    justifySelf: 'end',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
    '& > .MuiBox-root': {
      justifySelf: 'center',
      textAlign: 'center',
    },
  },
}));
