import { Box } from '@mui/material';
import React from 'react';
import { icons } from '../utils/icons';

interface StarRatingProps {
  rating: number;
}

export const StarRating: React.FC<StarRatingProps> = ({ rating }) => {
  return (
    <>
      <Box display='flex' columnGap={0.5}>
        {[...Array(Math.ceil(rating))].map((_, value) => {
          const starIcon =
            value + 1 <= rating ? icons.rating.star : icons.rating.halfStar;
          return <img key={value} src={starIcon} alt='' />;
        })}
      </Box>
    </>
  );
};
