import PersonContentType from '../content_types/person';
import PersonType from '../types/person';
import BaseWebservice from './base';

class PersonWebservice extends BaseWebservice<PersonContentType, PersonType> {
  contentType = 'person'

  parse({ profilePicture, skills, projects, testimonials, ...data }: PersonContentType) {
    return {
      ...data,
      profilePicture: {
        title: profilePicture.fields.title as string,
        url: profilePicture.fields.file.url as string,
      },
      skills: skills.map(({ fields: { logo, ...skill }}) => ({
        ...skill,
        logo: {
          title: logo.fields.title,
          url: logo.fields.file.url,
        }
      })),
      projects: projects.map(({ fields: { featuredImage, ...project } }) => ({
        ...project,
        featuredImage: {
          title: featuredImage.fields.title,
          url: featuredImage.fields.file.url,
        }
      })),
      testimonials: testimonials.map(({
        fields: {
          client: { fields: { profilePicture, ...client } },
          ...testimonial
        }}) => ({
        ...testimonial,
        client: {
          ...client,
          profilePicture: {
            title: profilePicture.fields.title,
            url: profilePicture.fields.file.url,
          }
        }
      }))
    }
  }
}

export default PersonWebservice
