import {
  Box,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import {
  ResponsiveContainer,
  StackedCarousel,
} from 'react-stacked-center-carousel';
import { CarouselSlide } from '.';

const CarouselWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  paddingTop: theme.spacing(5),
  '& > div.react-stacked-center-carousel': {
    overflow: 'visible !important',
  },
}));

const SlideInfoWrapper = styled(Box)(({ theme }) => ({
  maxWidth: 300,
  position: 'relative',
  paddingTop: theme.spacing(5),
  display: 'inline-block',
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(2),
  },
}));

export type CarouselSlideData = {
  image: string;
  title: string;
  subtitle: string;
  url: string;
};

interface CarouselProps {
  data: CarouselSlideData[];
}

export const Carousel: React.FC<CarouselProps> = ({ data }) => {
  const ref = useRef<StackedCarousel>();
  const [activeSlide, setActiveSlide] = useState(0);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { title, subtitle } = data[activeSlide];

  return (
    <>
      <ResponsiveContainer
        carouselRef={ref}
        render={(width, carouselRef) => {
          return (
            <CarouselWrapper>
              <StackedCarousel
                ref={carouselRef}
                slideComponent={CarouselSlide}
                slideWidth={smallScreen ? 300 : 470}
                height={smallScreen ? 420 : 600}
                carouselWidth={width}
                data={data}
                maxVisibleSlide={3}
                customScales={[1, 0.85, 0.7]}
                fadeDistance={0}
                transitionTime={450}
                onActiveSlideChange={(activeSlide) =>
                  setActiveSlide(activeSlide)
                }
              />
            </CarouselWrapper>
          );
        }}
      />
      <Box textAlign='center'>
        <SlideInfoWrapper>
          <Typography variant='h4'>{title}</Typography>
          <Box position='relative' pt={theme.spacing(1)}>
            <Typography variant='body2'>{subtitle}</Typography>
          </Box>
        </SlideInfoWrapper>
      </Box>
    </>
  );
};
