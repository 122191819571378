import { Box, Container, ContainerProps } from '@mui/material';
import React, { ReactNode } from 'react';

interface PageSectionProps extends ContainerProps {
  children: ReactNode;
  padding: string | number;
  backgroundColor?: string;
}

export const PageSection: React.FC<PageSectionProps> = ({
  children,
  padding,
  backgroundColor,
  maxWidth = 'md',
  style,
  ...rest
}) => (
  <Box
    style={{
      backgroundColor,
    }}
  >
    <Container
      maxWidth={maxWidth}
      style={{
        paddingTop: padding,
        paddingBottom: padding,
      }}
      {...rest}
    >
      {children}
    </Container>
  </Box>
);
