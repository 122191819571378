import { Box, List, styled, Typography, useTheme } from '@mui/material';
import React from 'react';
import { PageSection, SocialLinks } from '.';
import { Social } from './SocialLinks';

const LeftWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

const RightWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'end',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Links = styled(List)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.secondary.light,
  justifyContent: 'space-between',
  padding: 0,
  margin: 0,
  gap: 20,
}));

interface FooterProps {
  links: Record<Social, string>
}

export const Footer: React.FC<FooterProps> = ({
  links: { linkedIn, facebook, instagram, twitter }
}) => {
  const theme = useTheme();

  const currentYear = new Date().getFullYear();

  return (
    <PageSection
      padding={theme.spacing(6)}
      backgroundColor={theme.palette.primary.main}
      maxWidth='lg'
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        color: theme.palette.text.secondary,
        [theme.breakpoints.down('md')]: {
          gridTemplateColumns: '1fr',
        },
        gap: 4,
      }}
    >
      <LeftWrapper>
        <Typography
          variant='subtitle2'
          style={{
            fontSize: 20,
            lineHeight: 2,
            transform: 'rotate(-12deg)',
            color: theme.palette.text.secondary,
          }}
        >
          Let's Connect!
        </Typography>
        <SocialLinks
          links={{ linkedIn, instagram, facebook, twitter }}
        />
        <Box>
          <Typography color={theme.palette.text.secondary}>
            © Copyright {currentYear} Whitney Agunday | The Web Synthesis.
          </Typography>
          <Links>
            <a href='#privacy-policy'>Privacy Policy</a>
            <a href='#terms'>Terms and Conditions</a>
          </Links>
        </Box>
      </LeftWrapper>
      <RightWrapper>
        <Box>
          <Typography
            component='a'
            href='#top'
            color={theme.palette.text.secondary}
          >
            Back to top
          </Typography>
        </Box>
      </RightWrapper>
    </PageSection>
  );
};
