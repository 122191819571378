import {
  Box,
  List,
  ListItem,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { ButtonWithIcon, CallToAction, TwoColumnBox } from '.';
import SkillType from '../types/skill';
import { ResponsiveImage } from './ResponsiveImage';

const ToolsList = styled(List)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'start',
  alignItems: 'center',
  listStyleType: 'none',
  marginTop: 10,
  marginBottom: 0,
  marginLeft: 0,
  marginRight: 0,
  padding: 0,
  gap: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(3),
    justifyContent: 'center',
  },
}));

const ToolItem = styled(ListItem)(() => ({
  textDecoration: 'none',
  width: 'auto',
  padding: 0,
  '& > img': {
    margin: 'auto',
    height: '10vw',
    minHeight: 46,
    maxHeight: 60,
  },
}));

interface AboutSectionProps {
  skills: SkillType[];
}

export const AboutSection: React.FC<AboutSectionProps> = ({
  skills,
}) => {
  const theme = useTheme();

  return (
    <TwoColumnBox gridTemplateColumns='1fr 2fr'>
      <Box>
        <ResponsiveImage src='/images/about-person.png' alt='' />
      </Box>
      <Box display='flex' flexDirection='column' justifyContent='center'>
        <Box>
          <CallToAction
            title={'My UX Design Journey'}
            subtitle="Our family plumbing business was the foundation of my journey. As the manager, I work closely with my dad who handles the plumbing work. Being tech-savvy, I support him with bookings, hiring the best workers, and addressing clients' plumbing needs."
            moreInfo={
              <Box mt={theme.spacing(3)}>
                <Typography variant='h5'>
                Equipment utilized in my project work
                </Typography>
                <ToolsList>
                  {skills.map(({ name, logo: { url } }) => (
                    <ToolItem key={name}>
                      <img src={url} alt={name} />
                    </ToolItem>
                  ))}
                </ToolsList>
              </Box>
            }
            button={
              <ButtonWithIcon
                variant='secondary'
                label='Click here to read more'
                icon='/images/hearts.png'
                href='#'
                style={{
                  marginTop: theme.spacing(4),
                }}
              />
            }
          />
        </Box>
      </Box>
    </TwoColumnBox>
  );
};
